import * as React from "react"
import Layout from "../components/layout"
import DisclaimerComponent from "../components/disclaimer"

import Seo from "../components/seo"

const Disclaimer = () => {
  return (
    <Layout pageInfo={{ pageName: "disclaimer" }}>
      <Seo title="Disclaimer" />
      <DisclaimerComponent />
    </Layout>
  )
}

export default Disclaimer
