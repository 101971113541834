import React from "react"
import { Container } from "react-bootstrap"

const Disclaimer = () => (
  <>
    <Container className="section-margin px-5">
      <h2
        className="component-title d-flex justify-content-center"
        style={{ marginTop: "20vh" }}
      >
        No Investment Advice
      </h2>
      <p>
        The information provided on{" "}
        <strong className="text-secondary">FiBot.xyz</strong> does not
        constitute investment advice, financial advice, trading advice, or any
        other sort of advice, and you should not treat any of the website’s
        content as such. <strong className="text-secondary">FiBot</strong> does
        not recommend that any cryptocurrency should be bought, sold, or held by
        you. Nothing on this website should be taken as an offer to buy, sell or
        hold a cryptocurrency. Do conduct your own due diligence and consult
        your financial advisory before making any investment decision.{" "}
        <strong className="text-secondary">FiBot</strong> will not be held
        responsible for the investment decisions you make based on the
        information provided on the website.
      </p>
      <h2 className="component-title d-flex justify-content-center mt-4">
        Accuracy of Information
      </h2>
      <p>
        <strong className="text-secondary">FiBot</strong> will strive to ensure
        accuracy of information listed on this website although it will not hold
        any responsibility for any missing or wrong information. You understand
        that you are using any and all information available here AT YOUR OWN
        RISK. You should take adequate steps to verify the accuracy and
        completeness of any information on the website and do your own research.
      </p>
      <h2 className="component-title d-flex justify-content-center mt-4">
        Price Risk
      </h2>
      <p>
        The price of Bitcoin and other cryptocurrencies are very highly
        volatile. It is common for prices to increase or decrease by over 100%
        in a single day. Although this could mean potential huge profits, this
        also could mean potential huge losses. DO NOT INVEST ALL YOUR MONEY IN
        CRYPTOCURRENCIES. Only invest money which you are willing to lose.
        Cryptocurrency trading may not be suitable for all users of this
        website. Anyone looking to invest in cryptocurrencies should consult a
        fully qualified independent professional financial adviser.
      </p>
      <h2 className="component-title d-flex justify-content-center mt-4">
        General
      </h2>
      <p>
        ALL INFORMATION CONTAINED ON THE WEBSITE AND ITS SERVICES IS FOR GENERAL
        INFORMATIONAL USE ONLY AND SHOULD NOT BE RELIED UPON BY YOU IN MAKING
        ANY INVESTMENT DECISION. THE WEBSITE AND SERVICES DO NOT PROVIDE
        INVESTMENT ADVICE AND NOTHING ON THE WEBSITE AND SERVICES SHOULD BE
        CONSTRUED AS BEING INVESTMENT ADVICE. BEFORE MAKING ANY INVESTMENT
        CHOICE, YOU SHOULD ALWAYS CONSULT A FULLY QUALIFIED FINANCIAL AND/OR
        INVESTMENT ADVISER.
      </p>
      <p>
        The website and services include and/or may include links to external
        sites and co-branded pages or promote websites or services from other
        companies or offer you the ability to download software or content from
        other companies in order to provide you with access to information and
        services which you may find useful or interesting.{" "}
        <strong className="text-secondary">FiBot</strong> does not endorse such
        sites nor approve any content, information, legal or illegal emails
        (whether spam emails or not), goods or services provided by them.{" "}
        <strong className="text-secondary">FiBot</strong> is not responsible for
        and does not control those websites, services, emails, content and
        software and cannot accept any responsibility or liability for any loss
        or damage suffered by you as a result of your use of its website and
        services or of such external and/or co-branded sites.
      </p>
      <p>
        Image and graphic credits go to{" "}
        <a href="http://www.freepik.com" className="attr">
          pikisuperstar / Freepik{", "}
        </a>
        <a href="http://www.freepik.com" className="attr">
          upklyak / Freepik{", "}
        </a>
        <a href="http://www.freepik.com" className="attr">
          macrovector / Freepik
        </a>
      </p>
      <p>
        <strong className="text-secondary">FiBot</strong> is unable to exercise
        control over the security or content of information passing over the
        network, and <strong className="text-secondary">FiBot</strong> hereby
        excludes all liability of any kind for the transmission or reception of
        infringing or unlawful information of whatever nature.
      </p>
      <p>
        <strong className="text-secondary">FiBot</strong> accepts no liability
        for loss or damage suffered by you as a result of accessing the website
        and/or its services which contains any virus or which has been
        maliciously corrupted.
      </p>
    </Container>
  </>
)

export default Disclaimer
